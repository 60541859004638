// BeeBus.js
import React from 'react';
import '../styles/Satoori.css'; // CSS 연결
import Navbar from './Navbar'; // 네비게이션 바 추가
import im from '../images/satoori.webp'; 
import giticon from '../images/git.png'; 

import satoori1 from '../images/satoori1.png'; 
import satoori2 from '../images/satoori2.png'; 
import satoori3 from '../images/satoori3.png'; 
import satoori4 from '../images/satoori4.png'; 

import Layout from './Footer';
function Satoori() {
  return (
    <div>
            <Navbar /> {/* 네비게이션 바 렌더링 */}
      <div className="hero-container">
        <img 
          src={im} 
          alt="BeeBus Hero" 
          className="hero-image"
        />
      </div>
      <div className="Satoori-container">
        <h1>한국어 사투리 표준어 변환(KoBART)</h1>
        <p>
          ㅁㅁ
        </p>
        </div>

        <div className='team'>
        <h5>팀구성</h5>
        <p>개발: 1명</p>
        </div>

        <div className='time'>
        <h5>개발기한</h5>
        <p>7일</p>
        </div>

       <div className='top'>
        <h5>주요 기능</h5>
        <p>Text Transformation</p>
        </div>

        <div className='mid'>
        <h5>프로젝트 개요</h5>
        <p>
          LLM 모델을이용해 텍스트 요약을 진행하던도중 기획하게되었습니다 A(원문) → B(요약본)로 변환하는게 가능하면 다른 조건의 데이터도 가능하지않을까<br/>
          라는 생각에 AIHUB 에서 사투리 발화 데이터를 찾아 진행하게되었습니다<br/>
          <br/>
        </p>
        </div>

        <div className='ad'>
        <h5>기능 설명</h5>
        <p>
          사용자가 텍스트나 음성으로 사투리(원문) 을 입력합니다 이떄 텍스트인경우에는 그대로 변환이 진행되며 음성으로 입력받는경우 STT 를 한번 거치게됩니다<br/>
          입력받은 데이터를 표준어(번역문) 으로 변역합니다<br/>
        </p>
        </div>
        <div className='sp'>
        <h5>기술 스택</h5>
        <p>Fine-Tuning</p>
        <p>Data Preprocessing</p>
        <p>STT(Speech to Text)</p>
        </div>

        <div className='pp'>
        <img 
          src={satoori1} 
          alt="BeeBus Hero" 
          className="hero-image"
        />
                <img 
          src={satoori2} 
          alt="BeeBus Hero" 
          className="hero-image"
        />
                <img 
          src={satoori3} 
          alt="BeeBus Hero" 
          className="hero-image"
        />
                <img 
          src={satoori4} 
          alt="BeeBus Hero" 
          className="hero-image"
        />

        </div>


        <button className="bt"><a href='https://github.com/PSS-pr/Dialect-to-standard-language-translator-using-Ko-BERT.'>
      <img src={giticon} alt="Button Icon" />
      <p>깃허브 이동</p></a>
    </button>
        <Layout/>

    </div>
  );
}
    
    export default Satoori;
