// src/components/Footer.js
import React from 'react';
import '../styles/Footer.css';

function Footer() {
  return (
    <footer className="footer-container">
      <p>© Copyright 2024. All Rights Reserved.</p>
    </footer>
  );
}

export default Footer;
