// src/components/Home.js
import React from 'react';
import '../styles/Home.css';

function Home() {
  return (
    <section id="home" className="home-container">
      <h1>박성수의 포트폴리오</h1>
    </section>
  );
}

export default Home;
