// src/components/Navbar.js
import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../styles/Navbar.css';

function Navbar() {
  const navigate = useNavigate();

  const handleNavClick = (target) => {
    navigate('/'); // 메인 페이지로 이동
    setTimeout(() => {
      document.getElementById(target)?.scrollIntoView({ behavior: 'smooth' });
    }, 100); // 페이지가 로드된 후 스크롤
  };

  return (
    <nav className="navbar">
      <ul>
        <li onClick={() => handleNavClick('home')}>Home</li>
        <li onClick={() => handleNavClick('about')}>About</li>
        <li onClick={() => handleNavClick('skills')}>Skills</li>
        <li onClick={() => handleNavClick('projects')}>Projects</li>
        <li onClick={() => handleNavClick('educations')}>Others</li>
      </ul>
    </nav>
  );
}

export default Navbar;
