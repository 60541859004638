import React from 'react';
import '../styles/Yoyak.css'; // CSS 연결
import Navbar from './Navbar'; // 네비게이션 바 추가
import im from '../images/yoyak.webp'; 
import giticon from '../images/git.png'; 

import yoyak1 from '../images/yoyak3.png'; 
import yoyak2 from '../images/yoyak4.png'; 
import yoyak3 from '../images/yoyak5.png'; 
import yoyak4 from '../images/yoyak6.png'; 
import yoyak5 from '../images/yoyak7.png'; 
import yoyak6 from '../images/yoyak8.png'; 

import Layout from './Footer';
function Yoyak() {
  return (
    <div>
            <Navbar /> {/* 네비게이션 바 렌더링 */}
      <div className="hero-container">
        <img
          src={im} 
          alt="BeeBus Hero" 
          className="hero-image"
        />
      </div>
      <div className="Yoyak-container">
        <h1>한국어 텍스트 요약(KoBART)</h1>
        <p>
          이프로젝트는 LLM 모델에 대한 이해도를 높이기위한 프로젝트이며 KoBART 를 사용하였습니다
        </p>
        </div>

        <div className='team'>
        <h5>팀구성</h5>
        <p>개발: 1명</p>
        </div>

        <div className='time'>
        <h5>개발기한</h5>
        <p>3일</p>
        </div>

       <div className='top'>
        <h5>주요 기능</h5>
        <p>Text Summarization</p>
        </div>

        <div className='mid'>
        <h5>프로젝트 개요</h5>
        <p>
          LLM 모델에 대한 이해도를 높이기위해 기획한프로젝트입니다 <br/>
          데이터는 AIHub에서 참조하였고 뉴스,사회,시사 등 다양한 데이터를 사용하였습니다<br/>
        </p>
        </div>

        <div className='ad'>
        <h5>기능 설명</h5>
        <p>
          긴문장의 정보에대한 텍스트를 30% 이내로 요약합니다<br/>
        </p>
        </div>
        <div className='sp'>
        <h5>기술 스택</h5>
        <p>Fine-Tuning</p>
        <p>Data Preprocessing</p>
        </div>

        <div className='pp'>
        <img 
          src={yoyak1} 
          alt="BeeBus Hero" 
          className="hero-image"
        />
                <img 
          src={yoyak2} 
          alt="BeeBus Hero" 
          className="hero-image"
        />
                <img 
          src={yoyak3} 
          alt="BeeBus Hero" 
          className="hero-image"
        />
                <img 
          src={yoyak4} 
          alt="BeeBus Hero" 
          className="hero-image"
        />
                <img 
          src={yoyak5} 
          alt="BeeBus Hero" 
          className="hero-image"
        />                <img 
        src={yoyak6} 
        alt="BeeBus Hero" 
        className="hero-image"
      />                
        </div>

        <button className="bt"><a href='https://github.com/PSS-pr/Sentence-summarization-using-Ko-BERT./tree/main'>
      <img src={giticon} alt="Button Icon" />
      <p>깃허브 이동</p></a>
    </button>
        <Layout/>

  
    </div>
  );
}
    
    export default Yoyak;
