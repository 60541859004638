import React from 'react';
import '../styles/About.css';
import profileicon from '../images/profile.png'; 
import phoneicon from '../images/phone.png'; 
import emailicon from '../images/email.png'; 
import giticon from '../images/git.png'; 
import mypro from '../images/mypro.jpg';
import coment from '../images/ff.png';
function About() {
  return (
    <section id="about" className="about-container">
          <div className="side-image">
   <img src={mypro} alt="Profile Icon" />
                </div>

      <div className="content-container">
        {/* 왼쪽 이미지 */}
    

        {/* 오른쪽 박스 */}
        <div className="box-container">
        <h2>Backend Developer</h2>
          <div className="info-box">
            
            <img src={profileicon} alt="Profile Icon" />
            <p>박성수</p>
          </div>
          <div className="info-box">
            <img src={phoneicon} alt="Phone Icon" />
            <p>010-4453-8025</p>
          </div>
          <div className="info-box">
            <img src={emailicon} alt="Email Icon" />
            <p>knaray1@gmail.com</p>
          </div>
          <div className="info-box">
            <img src={giticon} alt="Git Icon" />
            <a href='https://github.com/PSS-pr'><p>https:github.com/PSS-pr</p></a>
          </div>
          <div className="info-box">
            <img src={coment} alt="Git Icon" />
            <p>웹과 앱을 개발하며 성장을 지향하는 Backend개발자 박성수입니다</p>
          </div>
        </div>
      </div>
    </section>
  );
}


export default About;
