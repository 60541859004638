import React from 'react';
import '../styles/Educations.css';

function Educations() {
  return (
    <section id="educations" className="educations-container">
      <h2>Others</h2>
      <div className="boxes-container">
        <div className="education-box">
          <h3>학력</h3>
          <p>부산 컴퓨터과학고등학교 소프트웨어과 졸업 (2018.03)</p>
          <p>신라대학교 소프트웨어공학부 졸업 (2024.08)</p>
        </div>
        <div className="education-box">
          <h3>수상</h3>
          <p>학부 전공역량강화 경진대회 장려(2018.10)</p>
          <p>신라대학교 PRIME4U 캡스톤 대상(2022.12)</p>
          <p>컴퓨터 공학부 학술제 최우수상(2023.12)</p>
        </div>
        {/* <div className="education-box">
          <h3>Activitie</h3>
        </div> */}
      </div>
      
    </section>
  );
}

export default Educations;
