import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Educations from './components/Educations';
import BeeBus from './components/BeeBus'; // BeeBus 컴포넌트 임포트
import BTC from './components/BTC';
import Layout from './components/Footer';
import Yoyak from './components/Yoyak';
import SSC from './components/SSC';
import Satoori from './components/Satoori';
import Azure from './components/Azure';
import './styles/App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route 
          path="/" 
          element={
            <div>
              <Navbar />
              <Home />
              <About />
              <Skills />
              <Projects />
              <Educations />
              <Layout />
            </div>
          } 
        />
        <Route path="/BeeBus" element={<BeeBus />} /> {/* 경로 수정 */}
        <Route path="/BTC" element={<BTC />} /> {/* 경로 수정 */}
        <Route path="/Azure" element={<Azure />} /> {/* 경로 수정 */}
        <Route path="/Yoyak" element={<Yoyak />} /> {/* 경로 수정 */}
        <Route path="/Satoori" element={<Satoori />} /> {/* 경로 수정 */}
        <Route path="/SSC" element={<SSC />} /> {/* 경로 수정 */}
      </Routes>
    </Router>
  );
}

export default App;
