import React from 'react';
import '../styles/BeeBus.css'; // CSS 연결
import Navbar from './Navbar'; // 네비게이션 바 추가
import Layout from './Footer';
import im from '../images/Bus.png'; 
import giticon from '../images/git.png'; 
function BeeBus() {
  return (
    <div className='mainp'>
      <Navbar /> {/* 네비게이션 바 렌더링 */}
      <div className="hero-container">
        <img 
          src={im} 
          alt="BeeBus Hero" 
          className="hero-image"
        />
      </div>
      <div className="BeeBus-container">
        <h1>Bee Bus (시각장애인 전용 버스앱)</h1>
        <p>
          BeeBus는 시각장애인을 위한 맞춤형 버스 앱으로, IOT와 연동하여 시각장애인 분들과의 인터뷰를 통해 불편한 점을 수집하여 개발한 안드로이드 전용 앱입니다.
        </p>
        </div>

        <div className='team'>
        <h5>팀구성</h5>
        <p>IOT: 2명</p>
        <p>UI: 2명</p>
        <p>프론트/백엔드: 1명</p>
        </div>

        <div className='time'>
        <h5>개발기한</h5>
        <p>2022.06 ~ 2022.12</p>
        </div>

       <div className='top'>
        <h5>주요 기능</h5>
        <p>TTS (Text-to-Speech)</p>
        <p>STT (Speech-to-Text)</p>
        </div>

        <div className='mid'>
        <h5>프로젝트 개요</h5>
        <p>
          이 프로젝트는 “왜 시각장애인들은 버스 정류장에서 본 적이 없는가?”라는 질문에서 출발하였습니다. <br/>
          실제 통계에 따르면, 시각장애인 분들은 버스 탑승을 가장 꺼려합니다. 그 이유는 불정확한 배치 간격과, 버스가 정차하는 위치를 알 수 없는 점 때문입니다. <br/>
          인터뷰를 통해 시각장애인 분들이 겪는 불편함을 듣고, 필요한 기능을 구상하여 앱에 반영하였습니다.<br/>
        </p>
        </div>

        <div className='ad'>
        <h5>기능 설명</h5>
        <p>
          STT 기능은 버스 검색 및 정류장 검색 시 사용되며, TTS는 앱 시작 시 데이터 로딩 과정에서 음성 입력값을 출력하여 <br/>
          검색이 완료된 후에도 사용자에게 새로 고침하여 알려줍니다. 또한 IOT와 연동하여 실시간 버스 위치와 사용자 거리 계산을 진행하며 <br/>
          일정 거리 이내로 접근할 경우 사용자에게 진동으로 알림을 제공합니다.<br/>
          {/* <a href='https:youtube.com'>유튜브링크</a> */}

        </p>
        </div>
        <div className='sp'>
        <h5>기술 스택</h5>
        <p>Android Studio</p>
        <p>Text-to-Speech</p>
        <p>Speech-to-Text</p>
        <p>Firebase</p>
        <p>Google APIs</p>
        <p>Web Hosting Server</p>
        </div>
        <div className='pp'>
  <h5>프로젝트 소개 영상</h5>
  <a href="https://youtu.be/_CAfbgoLiBQ" target="_blank" rel="noopener noreferrer">
    <img 
      src="https://img.youtube.com/vi/_CAfbgoLiBQ/maxresdefault.jpg" 
      alt="YouTube Thumbnail" 
      width="560" 
      height="315" 
      style={{ borderRadius: '10px', cursor: 'pointer' }} 
    />
  </a>
</div>

        <button className="bt"><a href='https://github.com/PSS-pr/Bus-App-for-the-Visually-Impaired-Busan-'>
      <img src={giticon} alt="Button Icon" />
      <p>깃허브 이동</p></a>
    </button>
        <Layout/>
        </div>
  );
}

export default BeeBus;
